import type { Integration, Client, SpanJSON } from '@sentry/core';
import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

const spansToIgnore = [
	'resource.other',
	'resource.link',
	'resource.css',
	'resource.script',
	'browser'
];

class CustomSpanFilterIntegration implements Integration {
	name: string = 'CustomSpanFilterIntegration';
	setup(client: Client): void {
		const options = client.getOptions();
		const originalBeforeSendSpan = options.beforeSendSpan;

		options.beforeSendSpan = (span: SpanJSON): SpanJSON | null => {
			if (spansToIgnore.includes(span.op ?? '')) {
				// Don't create the span
				return null;
			}
			return originalBeforeSendSpan ? originalBeforeSendSpan(span) : span;
		};
	}
}

if (env.PUBLIC_SENTRY_DSN) {
	let stage = env.PUBLIC_STAGE;
	if (stage) {
		stage = stage.toUpperCase();
	} else {
		stage = 'local';
	}
	Sentry.init({
		dsn: env.PUBLIC_SENTRY_DSN,
		environment: stage,
		release: env.PUBLIC_RELEASE,

		integrations: [
			CustomSpanFilterIntegration,
			Sentry.replayIntegration({
				// Please note that updating worker file requires also updating the version in the file name as otherwise it will load old cached version
				workerUrl: '/sentry-replay-worker-8-3-11.min.js'
			})
		],
		// This enables session replay in Sentry
		// The only need as of now is to record issues therefore only errors are recorded
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 0.1
	});
}

export const handleError = Sentry.handleErrorWithSentry();
